import ContentImage from "@/shared/ContentImage";
import { getImageByClippings, getImageBySelectedClipping } from "helpers";
import { StoryMainImage, ImageContainer} from "./style";

const ImageBody = ({content, onClickButtonSlider = (index)=>{}, positionImage = 0, elementBodyId}) => {
  const {
    value: {
      clippings,  
    },
    epigraphe,       
    selectedClipping,    
    customData = ""
  } = content;

  const dataImageBody = getImageByClippings(
    {
      desktop: ["modal_horizontal_md", "int_nota"],
      mobile: ["modal_horizontal_md", "col_listado_1x1"],
    },
    clippings
  );    
  dataImageBody.epigraphe = epigraphe;
  
  const dataImageManualCrop = getImageBySelectedClipping(selectedClipping); 
  if(dataImageManualCrop.image && dataImageManualCrop.image !== "#") {
    dataImageBody.desktop = dataImageManualCrop;
    dataImageBody.mobile = dataImageManualCrop;
  }
  return (
   <ImageContainer className={`image-embed ${customData != "" ? customData?.selectedView :  ""}` }onClick={() => onClickButtonSlider(positionImage)} id={elementBodyId}>
      <StoryMainImage>
        <ContentImage
          data={dataImageBody}
          alt={epigraphe}

        />
        <span className="epigraphe">{epigraphe}</span>
         
      <span className="picture-open">
           <button className="open-image" aria-label="Open Image" />
      </span>
      </StoryMainImage>
      </ImageContainer>
    
  );
};

export default ImageBody; 
