import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const ImageContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
margin:17px 0;
`;

export const StoryMainImage = styled.picture.attrs((props) => ({
  className: props.className,
}))`
  
  height: auto;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 1rem;
  margin: 17px 0;
  position: relative;
  display: block;
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 33%;
        border-radius: 0px;
        background-image: linear-gradient(transparent 0px, transparent 40%, rgba(25, 25, 25, 0.7), rgb(33, 33, 33));
        bottom: 0px;
        left: 0px;
        z-index:2;
      }
       img {
        object-fit: cover;
        margin:0!important;
      }

  .epigraphe {
      ${Font.sansRegular};
      font-size: 15px;
      letter-spacing: -0.15px;
      line-height: 20px;
      color: ${Color.white};
      margin-top: 10px;
      display: block;
      z-index:9;
      position:absolute;
      bottom:13px;
      padding: 0 12px;
      text-align: center;
      width: 100%;
     ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        color:${ColorDark.textLight};
        }
      }
        ${breakpoints.phone} {
        bottom: 15px!important;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        display: flex;
        padding: 0 0.5rem;
        justify-content: center;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; 
        overflow: hidden;
        text-overflow: ellipsis;
     }
   }
  .picture-open {
    overflow: auto;
    position: absolute;
    margin: 0;
    width:40px;
    height:40px;
    top: 15px;
    left: 17px;
    .open-image {
      border: none;
      display: flex;
      justify-content: center;
      height: 40px;
      border-radius: 60px;
      width: 40px;
      background: #fff;
      align-items: center;
      cursor: pointer;
      position: relative;
      &:before {
        content: "";
        background: url("/img/expand-icon.svg") no-repeat;
        height: 16px;
        width: 16px;
        position: absolute;
      }
    }
  }
`;
